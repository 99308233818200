import React from "react";
import { Container, Typography } from "@material-ui/core";
import trabajo_equipo from "../../assets/images/nosotros_cultura/trabajo_equipo.svg";
import experiencia from "../../assets/images/nosotros_cultura/experiencia.svg";
import diversion from "../../assets/images/nosotros_cultura/diversion.svg";
import compromiso from "../../assets/images/nosotros_cultura/compromiso.svg";
import pasion from "../../assets/images/nosotros_cultura/pasion.svg";
import companerismo from "../../assets/images/nosotros_cultura/companerismo.svg";
import aprendizaje from "../../assets/images/nosotros_cultura/aprendizaje.svg";
import servicio from "../../assets/images/nosotros_cultura/servicio.svg";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";

export default () => {
  return (
    <div
      style={{
        paddingTop: "40px",
        paddingBottom: "40px",
        backgroundColor: "white",
      }}
    >
      <Container>
        <Grid container direction="row">
          <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
            <Typography className="font-raleway" variant="h5">
              <label style={{ color: "black" }}>
                Nuestra cultura está definida por la suma de los valores de cada
                uno de los que integramos el{" "}
                <label style={{ color: "var(--rojo)" }}>equipo Manantial</label>
                , nuestro <label style={{ color: "var(--rojo)" }}>ADN</label>{" "}
                está definido claramente por
              </label>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          style={{ paddingTop: "60px", paddingBottom: "60px" }}
        >
          {/*
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>
                      Trabaja en equipo
                    </label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={trabajo_equipo} alt="Trabajo en equipo" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Experiencia</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={experiencia} alt="Experiencia" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          */}
        
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Servicio</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={servicio} alt="Servicio" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Aprendizaje</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={aprendizaje} alt="Aprendizaje"/>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Diversión</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={diversion} alt="Diversión" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          {/*
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Compromiso</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={compromiso} alt="Compromiso" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          */}

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card
              style={{
                backgroundColor: "var(--azul)",
                maxWidth: "318px",
                margin: "auto",
              }}
            >
              <CardActionArea
                style={{
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CardContent>
                  <Typography className="font-raleway-bold" variant="h5">
                    <label style={{ color: "var(--rojo)" }}>Pasión</label>
                  </Typography>
                </CardContent>
                <CardContent>
                  <img src={pasion} alt="Pasión" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          
        </Grid>
      </Container>
    </div>
  );
};
