import { Card, CardActionArea, CardActions, CardContent, Typography, Button, CardMedia } from '@material-ui/core'
import img from '../../../assets/images/aws_blog/post01.png'
import React from 'react';
import {Link} from "react-router-dom";


export const CardPublicacion = (props) => {

    return (
        <Card style={{ backgroundColor: "#232F3E", margin: "auto", minWidth: '200px', maxWidth: "300px" }}>
            <CardActionArea>
                <CardMedia
                    style={{ height: "180px" }}
                    image={img}
                    title="La búsqueda rápida y AWS"
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" style={{fontDisplay:"auto",color: "white" }}>
                        Debido al incremento de las ventas por el canal de e-commerce, se presenta una mayor
                        demanda de búsqueda de artículos y un factor importante para el cierre de la venta es
                        la búsqueda de los artículos.
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ maxHeight: '40px', alignItems: 'flex-end', justifyContent: 'center' }}>
                <Link
                to="/aws/casos-exito/la-busqueda-rapida-y-aws"
                style={{
                backgroundColor: "var(--rojo)",
                color: "white",
                padding: "4px 8px",
                borderRadius: "2px",
                }}
                size="small"
                color="primary"
                >
                <Button
                style={{
                backgroundColor: "var(--rojo)",
                color: "white",
                padding: "4px 8px",
                borderRadius: "2px",
                }}
                >
                    Ver Más 
                </Button>
                </Link>
            </CardActions>
        </Card>
    )
}
