import { Container, Typography } from '@material-ui/core'
import React from 'react'
import Grid from '@material-ui/core/Grid';
import CasoConect from "../../../assets/images/casos_de_exito/portada-delosi-connect.jpg";
import { Link } from "react-router-dom";
import { CabeceraPublicacion } from "./CabeceraPublicacion"


export const RecientePublicacion = () => {
    return (
        <div style={{ paddingTop: '60px', backgroundColor: "var(--plomo-oscuro)", paddingBottom: "10px" }}>
            <Container>
                <Grid container direction="row" justifyContent="center">
                    <Grid item sm={9} xs={12} style={{ padding: "10px 30px" }}>
                        <CabeceraPublicacion
                            img={CasoConect}
                            titulo="Transformando experiencIAs en tu Contact Center con AWS Connect"
                            contenido="Descubre como uno de los operadores de franquicias mas grandes del Perú moderniza las tareas de su contact center."
                        />
                        <center>
                            <Link to="/aws/casos-exito/central-telefonica-connect" style={{fontDisplay:"auto", textDecoration: "none" }}>
                                <Typography variant='h6' className="font-raleway" style={{fontDisplay:"auto", fontWeight: "bold" }}>
                                    Leer más...
                                </Typography>
                            </Link>
                        </center>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
