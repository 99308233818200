import React from 'react'
import waf from '../../../../src/assets/images/ofertas_aws/aws-waf.png'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormularioContacto from './../../../components/contacto/FomularioContacto';
import well from '../../../../src/assets/images/ofertas_aws/well-arch-delivery.png'

const useStyles = makeStyles((theme) => ({
    purposeContent: {
        padding: "20px",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        },
    },
    purposeIcon: {
        padding: "20px",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right'
        },
    },
    root: {
        width: "100%",
        marginTop: "50px",
    },
    heading: {
        fontSize: "17px",
        fontWeight: "bold",
    }
}));

export const OfertaWAF = () => {
    const classes = useStyles();

    return (
        <>

            <div style={{ paddingTop: "90px", paddingBottom: "40px", backgroundColor: "var(--aws-ligth)" }}>
                <Container>

                    <Grid container direction="row">

                        <Grid className={classes.purposeContent} item lg={8} md={8} sm={6} xs={12}>
                           <Grid item lg={12} md={12} sm={12} xs={12}>
                               <Typography className="font-raleway-bold" variant='h5'>
                                    <label style={{ color: 'var(--rojo)' }}>
                                        High-Performance Website Firewall
                                    </label>
                               </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingTop: "20px" }}>
                               <Typography className="font-raleway" variant='h5'>
                                    <label style={{ color: 'azul' }}>
                                        Firewall administrado en la nube enfocado en la supervisión y protección de sitios web finales en base a grupos de reglas, criterios definidos y métricas en tiempo real, capaz de hacerle frente a ataques de tipo SQL injection, cross-site scripting, HTTP flood y Bots.
                                    </label>
                               </Typography>
                            </Grid>
                        </Grid>

                        <Grid className={classes.purposeIcon} item lg={4} md={4} sm={6} xs={12}>
                           <img src={ waf } alt="proposito" style={{ maxWidth:"380px", minWidth:"350px" }}></img>
                        </Grid>

                    </Grid>

                </Container>
            </div>

            <div
              style={{
                paddingTop: "40px",
                paddingBottom: "40px",
                backgroundColor: "white",
              }}
            >
              <Container>
                <Grid container direction="row">
                  <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
                    <Typography className="font-raleway" variant="h5">
                      <label style={{ color: "black" }}>
                        Para la implementación de esta oferta de servicio se establecen en promedio 3 semanas hasta su finalización, las actividades a abarcar son las siguientes: 
                      </label>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  style={{ paddingTop: "60px", paddingBottom: "60px" }}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      style={{
                        backgroundColor: "var(--azul)",
                        maxWidth: "318px",
                        margin: "auto",
                      }}
                    >
                      <CardActionArea
                        style={{
                          textAlign: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <CardContent>
                          <Typography className="font-raleway-bold" variant="h5">
                            <label style={{ color: "white" }}>
                              Análisis de tráfico y elaboración de perfil
                            </label>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Card
                      style={{
                        backgroundColor: "var(--azul)",
                        maxWidth: "318px",
                        margin: "auto",
                      }}
                    >
                      <CardActionArea
                        style={{
                          textAlign: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <CardContent>
                          <Typography className="font-raleway-bold" variant="h5">
                            <label style={{ color: "white" }}>
                              Configuración de reglas y alertas
                            </label>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>

                </Grid>
              </Container>
            </div>

            <div style={{ paddingTop: "40px", paddingBottom: "40px", backgroundColor: "var(--azul)" }}>
                <Container>

                    <Grid container direction="row">

                        <Grid item xs={12} style={{ padding: "20px", textAlign:"center" }}>
                            <Typography className="font-raleway" variant='h5'>
                                <label style={{ color: 'white' }}>
                                        Alineados con el pilar de seguridad, Manantial impulsa el uso de los servicios de seguridad que dispone AWS en las diversas 
                                        etapas de implementación de sus clientes, todo ello alineado con el <label style={{ color: 'var(--rojo)' }}>AWS Well-Architected Framework</label> que 
                                        contiene las buenas prácticas y principios a seguir tanto en el diseño como la ejecución de cargas de trabajo en la nube
                                </label>
                             </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ padding: "20px", textAlign: "center" }}>
                           <img src={ well } alt="impacto" style={{ maxWidth:"450px", minWidth:"400px" }}></img>
                        </Grid>

                    </Grid>

                </Container>
            </div>

            <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
              <Typography style={{fontDisplay:"auto", fontWeight: "bold", fontSize: "24px" }}>
                Especializados en diversas áreas de tecnología
              </Typography>
              <div className={classes.root}>
                <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      Architect - Professional
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      El examen AWS Certified Solutions Architect - Professional
                      (SAP-C01) está destinado a personas que desempeñar un rol
                      profesional de arquitecto de soluciones. Este examen valida las
                      habilidades técnicas avanzadas y la experiencia en diseñar
                      aplicaciones y sistemas distribuidos en la plataforma AWS.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>
                      Security - Specialty
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      AWS Certified Security - Specialty valida su experiencia en la 
                      creación e implementación de soluciones de seguridad en la nube 
                      de AWS. Esta certificación también valida su conocimiento de las 
                      distintas clasificaciones especializadas de datos y los mecanismos 
                      de protección de datos de AWS; los métodos de cifrado de datos, 
                      así como también los mecanismos de AWS para implementarlos; y el 
                      protocolo de seguridad para Internet, además de los mecanismos de 
                      AWS para implementarlos.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion style={{ backgroundColor: "#E5E5E5", marginBottom: "20px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      SysOps Administrator - Associate
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      El examen AWS Certified SysOps Administrator - Associate acredita
                      que un candidato tiene la experiencia y las habilidades para
                      contribuir y “comenzar con el pie derecho” dentro de un equipo que
                      da soporte a un entorno de la nube de AWS. Los candidatos que
                      consigan la credencial deberían ser capaces de participar
                      rápidamente en una organización con una presencia de AWS y llevar
                      a cabo las tareas de trabajo que se esperan de alguien con uno o
                      dos años de experiencia brindando soporte a una variedad de cargas
                      de trabajo informáticas
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </div>
            </Container>

            <div style={{ paddingTop: '80px', backgroundColor: "var(--plomo-claro)", paddingBottom:'80px' }}>
                <Container>
                    <Typography className="font-sora" variant="h5" component="h2" style={{
                        textAlign: 'center',
                        paddingBottom: '80px'
                    }} >Para obtener mayor <label style={{ color: 'var(--rojo)' }}> información </label> sobre  nuestros servicios , por favor
                    <br></br>
                     contacte con nosotros mediante el siguiente <label style={{ color: 'var(--rojo)' }}> formulario.</label>
                    </Typography>
                </Container>

                <Container className="container-contacto" style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item xs={12} style={{ backgroundColor: 'var(--azul)', maxWidth: '520px'}} className="componente-formulario">
                            <FormularioContacto></FormularioContacto>
                        </Grid>                    
                </Container>

            </div>

        </>
    )
}