import React from "react";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { Principal } from "./views/vista-principal/Principal";
import { Page404 } from "./views/errores/Page404";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Servicios } from "./views/vista-servicios/Servicios";
import { Cultura } from "./views/vista-cultura/Cultura";
import { Clientes } from "./views/vista-clientes/Clientes";
import { Oportunidades } from "./views/vista-oportunidades/Oportunidades";
import { Formulario } from "./components/contacto/Formulario";
import { Equipo } from "./views/vista-equipo/Equipo";
import { CasosExito } from "./views/vista-aws/CasosExito";
import { CasoCloudSearch } from "./views/vista-aws/casos/CasoCloudSearch";
import ScrollIntoView from "./views/ScrollIntoView";
import { InformacionEmpresa } from "./components/informacion_empresa/InformacionEmpresa";
import { NuestroCompromiso } from "./views/vista-aws/NuestroCompromiso";
import { Ofertas } from "./views/vista-aws/Ofertas"
import { ProximosPasos } from "./views/vista-aws/ProximosPasos";
import { ServiciosAWS } from "./views/vista-aws/Servicios";
import { ServicioLambda } from "./views/vista-aws/servicios/ServicioLambda";
import { ServicioRDS } from "./views/vista-aws/servicios/ServicioRDS";
import { CasoScaling } from "./views/vista-aws/casos/CasoScaling";
import { ServicioConnect } from "./views/vista-aws/servicios/ServicioConnect";
import { NuestroCompromisoOracle } from "./components/oracle/NuestroCompromiso/NuestroCompromisoOracle";
import { CasoStreaming } from "./views/vista-aws/casos/CasoStreaming";
import { CasoConnect } from "./views/vista-aws/casos/CasoConnect";
import {Backup} from "./components/veeam/soluciones/Backup";
import {Politicas} from "./components/footer/politicas"
import { OfertaConnect } from "./views/vista-aws/ofertas/OfertaConnect";
import { OfertaWAF } from "./views/vista-aws/ofertas/OfertaWAF";

export default function App() {
  return (
    <div>
      <Router>
        <NavBar></NavBar>

        <ScrollIntoView />
        <Routes>
          <Route exact path="/" element={<Principal />} />
          <Route exact path="/servicios" element={<Servicios />} />
          <Route exact path="/nosotros/cultura" element={<Cultura />} />
          <Route exact path="/nosotros/equipo" element={<Equipo />} />
          <Route
            exact
            path="/clientes/nuestros-clientes"
            element={<Clientes />}
          />
          <Route
            exact
            path="/ayuda/trabaja-con-nosotros"
            element={<Oportunidades />}
          />

          <Route
            exact
            path="/contacto/empresa"
            element={<InformacionEmpresa />}
          />
          <Route exact path="/contacto/formulario" element={<Formulario />} />
          <Route exact path="/aws/ofertas" element={<Ofertas/>}/>
          <Route exact path="/aws/ofertas/connect" element={<OfertaConnect/>}/>
          <Route exact path="/aws/ofertas/waf" element={<OfertaWAF/>}/>
          <Route exact path="/aws/proximos-pasos" element={<ProximosPasos />} />

          <Route
            exact
            path="/aws/nuestro-compromiso"
            element={<NuestroCompromiso />}
          />
          <Route
            exact
            path="/politicas-privacidad"
            element={<Politicas />}
          />
          <Route exact path="/aws/casos-exito" element={<CasosExito />} />
          <Route
            exact
            path="/aws/casos-exito/la-busqueda-rapida-y-aws"
            element={<CasoCloudSearch />}
          />
          <Route
            exact
            path="/aws/casos-exito/escalabilidad-negocio"
            element={<CasoScaling />}
          />
          <Route
            exact
            path="/aws/casos-exito/central-telefonica-connect"
            element={<CasoConnect />}
          />
          <Route
            exact
            path="/aws/casos-exito/trasmitir-y-compartir-momentos"
            element={<CasoStreaming />}
          />
          {/* <Route exact path="/aws/casos-exito/rds" component={CasoRDS} /> */}
          <Route exact path="/aws/servicios" element={<ServiciosAWS />} />
          <Route
            exact
            path="/aws/servicios/Lambda"
            element={<ServicioLambda />}
          />
          <Route
            exact
            path="/aws/servicios/base-de-datos/RDS"
            element={<ServicioRDS />}
          />
          <Route
            exact
            path="/aws/servicios/aws-connect"
            element={<ServicioConnect />}
          />
          {/* <Route
              exact
              path="/demo/nuestras-demos"
              component={NuestrasDemos}
            /> */}
          {/* <Route exact path="/demo/nuestras-demos/consola-aws" component={Consola} />
            <Route exact path="/demo/nuestras-demos/reconocimiento-facial" component={Reconocimiento} />
            <Route
              exact
              path="/demo/nuestras-demos/consola-aws/interactuando-consola" component={InteractuandoConsola}
            /> */}

          <Route
            exact
            path="/oracle/nuestro-compromiso"
            element={<NuestroCompromisoOracle />}
          />
         {/*  <Route
            exact
            path="/oracle/proximos-pasos"
            element={<ProximosPasosOracle />}
          /> */}

          <Route exact path="/veeam/soluciones" element={<Backup/>}/>

          <Route path="*" element={<Page404 />} />
        </Routes>

        <Footer></Footer>
      </Router>
    </div>
  );
};
