import React from 'react'
import Cabecera from '../../components/cabecera/Cabecera'
import aws from '../../../src/assets/images/servicios_aws/aws.svg'
import { Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import CardPageNotFound from "./../../components/404/CardPageNotFound"

export const Ofertas = () => {

    return (
        <>
            <Cabecera
                titulo={[
                    "Ofertas de ", <label key="titulo_servicios_aws" style={{ color: 'var(--rojo)' }}>AWS</label>
                ]}
                icono={aws}
                descripcion="Innovación y experiencia para tu negocio"
            />

            <div style={{ backgroundColor: '#E6E9E9', height: '100%', paddingTop: '80px', paddingBottom: '80px' }}>
                <Container>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>

                        <Grid item md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="AI-Powered Contact Center"
                                description="Contact center capaz de brindar la mejor experiencia a sus consumidores bajo diversos mecanismos, canales y tecnologías que están a la vanguardia del mercado."
                                url="/aws/ofertas/connect"
                            >
                            </CardPageNotFound>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <CardPageNotFound
                                title="High-Performance Website Firewall"
                                description="Protege tus sitios web ante las amenazas más comunes de internet, manteniendo así la continuidad del negocio sin poner en riesgo a sus consumidores y su reputación."
                                url="/aws/ofertas/waf"
                            >
                            </CardPageNotFound>
                        </Grid>

                    </Grid>
                </Container>
            </div>

        </>
    )
}