import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NuestrosServicios } from '../../components/servicios/NuestrosServicios'
import {NuestrosClientes} from '../../components/clientes/NuestrosClientes'
import Experiencia from '../../components/experiencia/Experiencia'
import Particles from '../../components/slide/particles'

export const Principal = () => {
    return (
        <div>
            <Particles/>
            <NuestrosServicios></NuestrosServicios>
            <NuestrosClientes></NuestrosClientes>
            <Experiencia></Experiencia>
        </div >
    )
}
